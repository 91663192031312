<template>
	<el-dialog top="1vh" title="司机详情" width="1050px" :visible.sync="is_show_in_page">
		<el-form class="form_ordinary" label-width="160px">

		<div class="big_tit" style="margin-top:0px;">基本数据</div>
		<div class="tab1_inner">
			<el-form-item class="el_form_item" label="司机姓名" style="width: 45%">
			<el-input class="el_inner_width" v-model="form.driver_name" disabled></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机电话" style="width: 45%">
			<el-input class="el_inner_width" v-model="form.driver_tel" disabled ></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="身份证号" style="width: 45%; margin: 10px 0px" >
			<el-input class="el_inner_width" v-model="form.id_card_num" disabled></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="数据完整性" style="width: 45%; margin: 10px 0px">
			<el-input class="el_inner_width" v-model="data_complete" disabled></el-input>
			</el-form-item>
		</div>
		<div class="tab1_inner">
			<el-form-item class="el_form_item" label="驾驶证号码" style="width: 45%">
				<el-input class="el_inner_width" v-model="form.id_card_num"></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="准架车型" style="width: 45%">
				<el-input class="el_inner_width" v-model="form.dl_class"></el-input>
			</el-form-item>
		</div>
		<div class="tab1_inner">
			<el-form-item class="el_form_item" label="驾驶证有效期开始日期" style="width:45%">
			<el-date-picker style="width: 265px" v-model="time.dl_start_date" type="date" placeholder="选择日期" value-format="timestamp"></el-date-picker>
			</el-form-item>
			<el-form-item class="el_form_item" label="驾驶证有效期结束日期" style="width: 44%">
			<el-date-picker style="width: 265px" v-model="time.dl_end_date" type="date" placeholder="选择日期" value-format="timestamp"></el-date-picker>
			</el-form-item>
		</div>
		<div class="tab1_inner">
			<el-form-item class="el_form_item" label="从业资格证号码" style="width: 45%">
				<el-input class="el_inner_width" v-model="form.cy_num"></el-input>
				<div class="cy_num_text">
					{{cy_num_text}}
				</div>
			</el-form-item>
			<el-form-item class="el_form_item" label="从业资格证行政号码" style="width: 45%">
				<el-cascader class="el_input" v-model="ser_para.ser_cities" :options="ser_para.cities" filterable @change="ser_para_case_cities_change" style="width: 99px"></el-cascader>
			<el-input class="el_inner_width"  v-model="form.cy_code" style="width: 158px;margin-left:8px"></el-input>
			</el-form-item>
		</div>
		<div class="tab1_inner">
			<el-form-item class="el_form_item" label="驾驶证发证机关" style="width: 45%">
				<el-input class="el_inner_width" v-model="form.dl_ca"></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="是否可以自己接单">
				<el-select class="el_input" v-model="form.is_act" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="可以" value="1"></el-option>
					<el-option label="不可以" value="2"></el-option>
				</el-select>
			</el-form-item>
		</div>
		<div class="tab1_inner">
		</div>
			<div class="big_tit">证件相关</div>
			<div class="tab1_inner">
				<div class="el_form_item" label="" style="width:48%">
					<div class="img_btn">
						<div v-if="!dl_img_src" class="btn">未上传</div>
					    <el-image
							v-else
							class="img"
							:src="dl_img_src"
							:previewSrcList="srcList"
							:z-index = 3000
							>
						</el-image>
						<!-- <img  v-else :src="dl_img_src" class="img" @click="show_img(dl_img_src)"> -->
					</div>
					<div class="bottom_text">驾驶证
						<el-button class="btn_left" size="mini" type="text" @click="voucher_add(1)" v-if="driver_info.status_text != '审核通过'">重新上传</el-button>
					</div>
				</div>
				<div class="el_form_item" style="width:48%;margin-left:1%">
					<div class="img_btn">
						<div v-if="!cy_img_src" class="btn">未上传</div>
						  <el-image
								v-else
								class="img"
								:src="cy_img_src"
								:previewSrcList="cyList"
								:z-index = 3000
								>
							</el-image>
						<!-- <img  v-else :src="cy_img_src" class="img" @click="show_img(cy_img_src)"> -->
					</div>
					<div class="bottom_text">从业资格证
						<el-button class="btn_left" size="mini" type="text" @click="voucher_add(2)" v-if="driver_info.status_text != '审核通过'">重新上传</el-button>
					</div>
				</div>
			</div>


			<div class="big_tit">审核相关</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="驳回原因" style="width:100%">
					 <el-autocomplete
						class="el_inner_width"
						v-model="form.faild_reason"
						:fetch-suggestions="querySearch"
						placeholder="请输入内容"
					></el-autocomplete>
					<!-- <el-input class="el_inner_width" v-model="form.faild_reason" clearable></el-input> -->
				</el-form-item>
			</div>

		</el-form>

		<div class="bottom_btns">
			<el-checkbox v-model="manual_ver_checked" @change="manual_ver_change">五证齐全</el-checkbox>
			<el-button  style="margin-left:20px" type="success" @click="pass()">通过</el-button>
			<el-button type="danger" @click="unpass()">驳回</el-button>
		</div>

		    <!-- 凭证添加界面 -->
		<el-dialog
			title="证件上传"
			top="2vh"
			width="500px"
			:visible.sync="upl.is_show"
			:modal="false"
			>
			<div class="upl">
				<div class="img_area" @click="voucher_add_sub">
				<img v-if="upl.src" :src="upl.src" class="show" />
				<i v-else class="el-icon-plus avatar-uploader-icon icon"></i>
				</div>

				<div class="btn_area">
				<el-button type="primary" @click="voucher_sub(num)">上传</el-button>
				</div>
			</div>
		</el-dialog>
	</el-dialog>
</template>
<script>

	export default {
		props:{
			is_show:Number,//是否显示
			driver_info:Object,//司机详情
		},
		data() {
			return {

				//页面是否显示
				is_show_in_page:false,

				//司机id
				driver_id:'',
				manual_ver_checked:'',
				//表单
				form:{
					driver_name: "", //司机姓名
					driver_tel: "", //司机电话
					id_card_num: "", //司机身份证号
					faild_reason: "", //审核失败原因
					dl_num:'',//驾驶证号码
					dl_class:'',//准假车型(类似:A1A2 / A1 / A2)
					dl_ca:'',//驾驶证发证机关
					cy_num:'',//从业资格证号码
					cy_code:'',//从业资格证行政号码
					dl_start_date:'', // 驾驶证有效期开始日期
					dl_end_date:'', // 驾驶证有效期结束日期
					is_act:'',//是否可以自己接单
					manual_ver:'',//五证是否齐全
				},
				cy_num_text:'',
				//日期
				time:{
					dl_start_date:'',
					dl_end_date:'',
				},
				ser_para:{
					ser_cities:"",
					cities:[
					{label:'北京市',value:'110000'},
					{label:'天津市',value:'120000'},
					{label:'河北省',value:'130000'},
					{label:'山西省',value:'140000'},
					{label:'内蒙古自治区',value:'150000'},
					{label:'辽宁省',value:'210000'},
					{label:'吉林省',value:'220000'},
					{label:'黑龙江省',value:'230000'},
					{label:'上海市',value:'310000'},
					{label:'江苏省',value:'320000'},
					{label:'浙江省',value:'330000'},
					{label:'安徽省',value:'340000'},
					{label:'福建省',value:'350000'},
					{label:'江西省',value:'360000'},
					{label:'山东省',value:'370000'},
					{label:'河南省',value:'410000'},
					{label:'湖北省',value:'420000'},
					{label:'湖南省',value:'430000'},
					{label:'广东省',value:'440000'},
					{label:'广西壮族自治区',value:'450000'},
					{label:'海南省',value:'460000'},
					{label:'重庆市',value:'500000'},
					{label:'四川省',value:'510000'},
					{label:'贵州省',value:'520000'},
					{label:'云南省',value:'530000'},
					{label:'西藏自治区',value:'540000'},
					{label:'陕西省',value:'610000'},
					{label:'甘肃省',value:'620000'},
					{label:'青海省',value:'630000'},
					{label:'宁夏回族自治区',value:'640000'},
					{label:'新疆维吾尔自治区',value:'650000'},
					{label:'台湾省',value:'710000'},
					{label:'香港特别行政区',value:'810000'},
					{label:'澳门特别行政区',value:'820000'},
					]
				},
				data_complete:"", // 数据完整性
				//驾驶证
				dl_img_src:'',
				srcList: [],
				//从业资格证
				cy_img_src:'',
				//上传界面
				upl: {
					//是否显示上传界面
					is_show: false,

					//地址
					src: "",

					//备注
					mark: "",
				},
			    cyList: [],
				//大图
				img:{
					is_show:false,
					src:''
				},
				//失败原因列表
				restaurants: [],
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
					this.img.is_show=false
				}
			},
			driver_info(new_data){
				if(new_data.id){
                  this.load_data(new_data)
				}
			}
		},
		mounted() {
		  this.restaurants = this.loadAll();
		},
		methods: {
			manual_ver_change(vuale){
				if(vuale){
					this.form.manual_ver =2
				}else{
					this.manual_ver = 1
				}
			},
			querySearch(queryString, cb) {
				var restaurants = this.restaurants;
				var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
				// 调用 callback 返回建议列表的数据
				cb(results);
			},
			createFilter(queryString) {
				return (restaurant) => {
				return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
				};
			},
			loadAll() {
				return [
				{ "value": "请上传从业资格证", "address": "请上传从业资格证" },
				{ "value": "请上传驾驶证", "address": "请上传驾驶证" },
				];
			},
			load_data(new_data){
				this.ser_para.ser_cities = ''
				// 置入列表数据
				this.driver_id = new_data.id;
				this.form.driver_name = new_data.app_user_info.name;
				this.form.driver_tel = new_data.app_user_info.tel;
				this.form.faild_reason = new_data.faild_reason;
				this.form.id_card_num = new_data.app_user_info.id_card_num;
				this.form.dl_num =new_data.dl_num;
				this.form.dl_class =new_data.dl_class;
				this.form.dl_ca =new_data.dl_ca;
				this.form.manual_ver = new_data.manual_ver
				if(new_data.manual_ver ==2){
					this.manual_ver_checked = true
				}else{
					this.manual_ver_checked = false
				}
			    //从业资格证号码
				if(new_data.cy_num && new_data.cy_num.trim() != 'need_check'){
					this.form.cy_num =new_data.cy_num;
					this.cy_num_text = ''
				}else if(new_data.cy_num.trim() === 'need_check' || !new_data.cy_num){
					this.cy_num_text = '自动识别失败'
					//没有 设置为身份证号码
					this.form.cy_num =new_data.app_user_info.id_card_num;
				}
				
				this.form.cy_code =new_data.cy_code;
				this.form.dl_start_date =new_data.dl_start_date;
				this.form.dl_end_date =new_data.dl_end_date;
				this.form.is_act = new_data.is_act
				if(new_data.xieyi&&new_data.id_card_img1&&new_data.id_card_img2&&new_data.dl_img&&new_data.cy_img){
					this.data_complete = "资料齐全"
				}else{
					this.data_complete = "资料不齐全"
				}
				//日期处理
				if(new_data.dl_start_date !=0){
					this.time.dl_start_date = Number(new_data.dl_start_date) *1000
					}else{
					this.time.dl_start_date = ''
					}
				if(new_data.dl_end_date !=0){
					this.time.dl_end_date = Number(new_data.dl_end_date) * 1000
					}else{
					this.time.dl_end_date = ''
				}
				if(!this.form.dl_class || this.form.dl_class =="未填写"){
					this.form.dl_class = new_data.class
				}
				
				// let key_arr=new_data.dl_img.split('__v__')
				this.dl_img_src=this.$my.qiniu.make_src('driverdl',new_data.dl_img)
				this.srcList[0]=this.$my.qiniu.make_src('driverdl',new_data.dl_img)
				this.cy_img_src=this.$my.qiniu.make_src('drivercy',new_data.cy_img)
				
				//从业资格证行政号码
				this.form.cy_code = this.form.id_card_num.substr(0,2)+'0000'
				this.ser_para.ser_cities = this.form.cy_code
				// console.log(this.form.cy_code)
				this.cyList[0]=this.$my.qiniu.make_src('drivercy',new_data.cy_img)
				

			},
			voucher_add(num) {
				this.num = num;
				//打开添加凭证界面

				//打开上传界面
				this.upl.is_show = true;

				//清空上次上传的图片
				this.upl.src = "";

				//清空备注
				this.upl.mark = "";
			},
		     //省市区
			ser_para_case_cities_change(event) {
			this.form.cy_code = event[0]
			},
			voucher_sub(num) {
			//上传
			this.$my.qiniu.upl({
				bucket: "driversourceproof",
				file_path: this.upl.src,
				callback: (data) => {
				var key = data.key.split('__v__1')
				//清空
				var dl_img = "";
				var cy_img = "";
				// 判断
				if (num == 1) {
					dl_img = key[0];
				} else {
					cy_img = key[0];
				}
				// //上传
				this.$my.net.req({
					data: {
					mod: "truck_driver",
					ctr: "driver_img",
					id: this.driver_info.id,
					dl_img,
					cy_img,
					},
					callback: (data) => {
						if(num ==1){
							this.dl_img_src =this.upl.src
						}else{
							this.cy_img_src =this.upl.src
						}
					//关闭添加凭证界面
					this.upl.is_show = false;

					// //刷新数据
					this.init();
					},
				});
				},
			});
		},
		voucher_add_sub() {
			//提交凭证

			//选择图片
			this.$my.qiniu.choose_img({
				success: (res) => {
				//预览
				this.upl.src = res.src;
				},
			});
		},
		// 初始化
		init() {
			this.$my.net.req({
				data: {
				mod: "truck_driver",
				ctr: "driver_list_by_admin",
				truck_plate_num: this.driver_info.truck_plate_num,
				},
				callback: (data) => {
				data.list.forEach((item) => {
					if (item.creat_time == this.driver_info.creat_time) {
					this.load_data(item);
					}
				});
				},
			});
		},
			//不通过
			unpass(){

				//失败原因
				if(!this.form.faild_reason.trim()){
					this.$my.other.msg({
						type:'warning',
						str:'缺少失败原因'
					});
					return;
				}

				//询问
				this.$my.other.confirm({
					content:"点击'确定'驳回审核",
					confirm:()=>{
						
						//调接口
						this.$my.net.req({
							data:{
								mod:'truck_driver',
								ctr:'driver_edit_by_admin',
								driver_id:this.driver_id,
								status:3,
								faild_reason:this.form.faild_reason.trim()
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.is_show_in_page=false
								this.$emit('close');
							},
						});
					}
				});
			},

			//通过
			pass(){
				let new_data=this.form;
				let start_time = Number(this.time.dl_start_date) /1000
				let end_time = Number(this.time.dl_end_date) /1000
				//询问
				this.$my.other.confirm({
					content:"点击'确定'通过审核",
					confirm:()=>{
						
						//调接口
						this.$my.net.req({
							data:{
								mod:'truck_driver',
								ctr:'driver_edit_by_admin',
								driver_id:this.driver_id,
								status:2,
								dl_num:new_data.dl_num,
								dl_class:new_data.dl_class,
								dl_start_date:start_time,
								dl_end_date:end_time,
								is_act:new_data.is_act,
								cy_num:new_data.cy_num,
								cy_code:new_data.cy_code,
								dl_ca:new_data.dl_ca,
								manual_ver:this.form.manual_ver,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.is_show_in_page=false
								this.$emit('close');
							},
						});
					}
				});
			},
		}
	}
</script>
<style lang="scss" scoped>

.el_form_item {
  margin: 0;
  .img_btn {
    border: 1px solid #bbb;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px;
    height: 300px;
    overflow: hidden;
    .btn {
      text-align: center;
      line-height: 290px;
      font-size: 14px;
    }

    .img {
      position: relative;
      display: block;
      height: 290px;
      /deep/.el-image__inner {
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: auto !important;
      }
    }
  }
  .bottom_text {
    margin-top: 5px;
    text-align: center;
  }
}
.big_tit {
  background-color: #eee;
  padding: 10px 20px;
  color: #666;
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
}
.tab1_inner {
  padding: 15px 15px 0 15px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.el_inner_width {
  width: 94%;
}

.bottom_btns {
  text-align: center;
  margin-top: 30px;
}

.form_ordinary {
  // height: calc(100% - 91px);
  padding: 10px 0;
  overflow-y: auto;
}
.upl {
  .img_area {
    border: 1px solid #ccc;
    cursor: pointer;
    padding: 5px;
    .show {
      margin: auto;
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
    .icon {
      text-align: center;
      display: block;
      font-size: 100px;
      height: 500px;
      line-height: 500px;
    }
  }
  .mark_area {
    border: 1px solid #ccc;
    padding: 5px;
    width: 448px;
    margin-top: 10px;
    height: 100px;
    outline: none;
    font-size: 16px;
  }
  .mark_area::placeholder {
    font-size: 20px;
    line-height: 100px;
    text-align: center;
  }
  .btn_area {
    text-align: center;
    margin-top: 15px;
  }
}

.voucher {
  margin-top: -10px;
  .header {
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
  .list_none {
    height: 300px;
    line-height: 300px;
    text-align: center;
    font-size: 18px;
  }
}
.cy_num_text{
	font-size: 12px;
	color: red;
}
</style>