<template>
	<el-container style="height: 100%">
		
		<!-- 左侧 -->
		<el-aside :width="fold?'auto':'170px'">
			
			<!-- 深蓝色背景  #00161d  color #fff -->
			<el-menu 
				class="main_menu" 
				@select="select" 
				:collapse="fold" 
				:unique-opened="true" 
				:collapse-transition="false" 
				:default-active="currentMenu"
			>
				
				<!-- 左侧logo -->
				<div class="logo"><img src="../../res/img/logo.png"></div>

				<!-- 左侧菜单 -->
				<el-submenu v-for="(group,idx) in menu" :key="idx" :index="idx.toString()">
					<!-- 菜单组 -->
					<template slot="title">
						<i class="icon" :class="group.icon?group.icon:'el-icon-menu'"></i>
						&nbsp;&nbsp;
						<span slot="title">{{group.name}}</span>
					<span v-if="group.jb != 0">
						<el-badge :value="group.jb" class="item" id="badge"> </el-badge>
					</span>
					</template>

					<!-- 单个菜单 -->
					<el-menu-item v-for="(item,index) in group.children" :key="index" :index="idx+'-'+index">{{item.name}}
					<span v-if="item.jb != 0">
						<el-badge :value="item.jb" class="item" id="badge"> </el-badge>
					</span>
					</el-menu-item>
				</el-submenu>
			</el-menu>
		</el-aside>

		<!-- 右边 -->
		<el-container class="right_container">

			<!-- 头部一行 -->
			<el-header class="right_header" style="height:50px">
				<div class="left">
					<i :class="'text-xxl '+(fold?'el-icon-s-unfold':'el-icon-s-fold')"  @click="menu_toggle"></i>
					<span class="open-page" @click="open_page">
						新窗口打开
					</span>
				</div>
				<!-- <div class="centre">
					<el-breadcrumb separator-class="el-icon-arrow-right">
					    <el-breadcrumb-item :to="{ name: 'index' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item :to="item.url" v-for="(item,index) in breadcrumb_arr" :key="index" :replace="true">{{item.name}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div> -->

				<div class="right">
					<div class="text">{{user_info.name}}({{ug_name}})</div>
					<div class="el-icon-switch-button logout_icon" @click="logout" ></div>
					<div id="show" @click="psw_edit"><i class="el-icon-edit"></i></div>
					
				</div>
			</el-header>
			<!-- 内容区域 -->
			<el-main class="container">
				<router-view></router-view>
			</el-main>
		</el-container>
		<el-dialog
			title="修改密码"
			:visible.sync="psw_edit_show"
			width="30%"
			@close="psw_edit_close"
			>
			<div class="tab1_inner">
				<el-form class="form" label-width="100px">
				<el-form-item class="el_form_item" label="原密码" style="width:388px">
						<el-input v-model="old_pwd" type="password" :show-password="true" placeholder="请输入登录密码" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="新密码" style="width:388px">
					<el-input v-model="new_pwd" type="password" :show-password="true" placeholder="请输入登录密码" clearable></el-input>
				</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="psw_edit_confirm">确 定</el-button>
			</span>
		</el-dialog>
	</el-container>
</template>
<script>
	import {mapMutations, mapState} from 'vuex'
import voucher_after_endVue from '../transit_real_time/voucher/voucher_after_end.vue';
	export default {
		data() {
			return {
				timer:'',
				//菜单是否折叠
				fold: false,

				//选中菜单
				currentMenu: '',

				//菜单数据
				menu: [],

				//用户组名称
				ug_name:'',

				psw_edit_show:false,

				old_pwd:'',//原密码

				new_pwd:'',//新密码

				breadcrumb_arr:[],//点击过得数组
			}
		},
		computed:{
			...mapState(['user_info'])
		},
		created() {
			this.menu_init();
			this.fold_init()
			this.msg_tips()

		},
		methods: {

			...mapMutations(['end_of_logout']),
			//打开新的浏览器页面
			open_page(){
				console.log(this.$route)
				window.open('#'+this.$route.path)
			},
			psw_edit_confirm(){
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'edit_pwd_admin',
						old_pwd:this.old_pwd,
						new_pwd:this.new_pwd
					},callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'修改成功'
						});
						this.psw_edit_show = false
					}
				})
			},
			//修改密码
			psw_edit(){
				this.psw_edit_show = true
			},
			//修改密码弹窗关闭
			psw_edit_close(){
				this.old_pwd = '',
				this.new_pwd = ''
			},
			// 消息弹窗
			message_Popup(titel) {
				Notification.requestPermission((status) => {
					if (status === "granted") {
					let notify = new Notification("骏工物联提示", {
						// icon: "../../res/img/logo.png",
						body: titel,
					});
					// 点击时桌面消息时触发
					notify.onclick = () => {
						// 跳转到当前通知的tab,如果浏览器最小化，会将浏览器显示出来
						window.focus();
					};
					}
				});
			},
			//消息提示
			msg_tips(){
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:"get_unchecked_num",
					},callback:(v)=>{
						let menu_list = this.menu
						for (let item in menu_list) {
							let date = menu_list[item]
							// console.log(date.children[data],999)
							if(menu_list[item].name =='风控管理'){
								menu_list[item].jb = v.feng_xian_guan_li
							}
							// if(menu_list[item].name =='货车管理'){
							// 	menu_list[item].jb = v.app_user_a_truck_list
							// }
							// if(menu_list[item].name =='财务管理'){
							// 	menu_list[item].jb = v.finance_a_yufu_list
							// }
							for(let data in date.children){
								if(date.children[data].name =='司机审核'){
									if(v.app_user_a_driver_list>0 &&this.ug_name=="运营审核组"){
										this.message_Popup("您有新的待审核司机")
									}
									date.children[data].jb = v.app_user_a_driver_list
								}
								if(date.children[data].name =='货车审核'){
									if(v.app_user_a_truck_list>0 &&this.ug_name=="运营审核组"){
										this.message_Popup("您有新的待审核货车")
									}
									date.children[data].jb = v.app_user_a_truck_list
								}
								if(date.children[data].name =='垫资审核'){
									if(v.finance_a_yufu_list>0 &&this.ug_name=="运营审核组"){
										this.message_Popup("您有新的待审核预付运单")
									}
									date.children[data].jb = v.finance_a_yufu_list
								}
								if(date.children[data].name =='公司审核'){
									if(v.finance_a_yufu_list>0 &&this.ug_name=="运营审核组"){
										this.message_Popup("您有新的待审核公司")
									}
									date.children[data].jb = v.app_user_a_company_list
								}
								if(date.children[data].name =='提现银行卡'){
									if(v.finance_a_yufu_list>0 &&this.ug_name=="运营审核组"){
										this.message_Popup("您有新的待审核银行卡")
									}
									date.children[data].jb = v.app_user_a_app_user_bankcard
								}
								if(date.children[data].name =='代理列表'){
									if(v.finance_a_yufu_list>0 &&this.ug_name=="运营审核组"){
										this.message_Popup("您有新的待审核代理")
									}
									date.children[data].jb = v.app_user_a_app_agent_list
								}
								if(date.children[data].name =='app用户'){
									if(v.finance_a_yufu_list>0 &&this.ug_name=="运营审核组"){
										this.message_Popup("您有新的待审核app用户")
									}
									date.children[data].jb = v.app_user_a_user_list
								}
							}
						}
						this.menu = menu_list
						// if(data.truck_list >0&&this.ug_name=="运营审核组"){
						// 	this.message_Popup("您有新的待审核货车")
						// }
						// if(data.yufu_list>0 &&this.ug_name=="运营审核组"){
						// 	this.message_Popup("您有新的待审核预付运单")
						// }
					}
				})
			    // 定时器更新消息提示
				this.timer = setTimeout(() => {
					this.msg_tips();
				}, 300000);
			},
			//菜单展开折叠
			menu_toggle() {
				this.fold = this.fold ? false : true

				if(this.fold){
					localStorage.setItem('_pages_index_fold',1);
				}else{
					localStorage.setItem('_pages_index_fold',2);
				}
			},

			//菜单初始化
			fold_init(){
				let _pages_index_fold=localStorage.getItem('_pages_index_fold');

				if(_pages_index_fold==1){
					this.fold=true;
				}else if(_pages_index_fold==2){
					 this.fold=false;
				}
			},
			
			//菜单跳转
			select(index,indexPath) {
				var idx = indexPath[1].split("-")
				var menu = this.menu[idx[0]].children[idx[1]]
				var path = "/pages/"+menu.url.replace("#", "")
				if (path != this.$route.fullPath) {
					this.$router.push({ path: path })
				}
				//记录点击过得页面
				this.breadcrumb_arr.push({
					name:menu.name,
					url:path
				})
				this.breadcrumb_arr = Array.from(new Set(this.breadcrumb_arr))
				console.log(this.breadcrumb_arr)
			},

			//退出登录
			logout(){
				this.$my.other.confirm({
					content:'退出登录,请确认?',
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'admin',
								ctr:'logout_by_admin',
							},
							callback:(data)=>{

								//删除缓存
								this.end_of_logout();

								//跳转回登录页
								this.$router.push('/login');
							}
						});
					}
				});
			},

			//菜单初始化
			menu_init() {

				//用户组名称
				this.ug_name=this.user_info.ug_info.name

				//取出原始菜单列表
				let menu_list=this.user_info.menu_list
				//格式化主菜单
				let menu_main={};
				let menu_children={};
				for(var item of menu_list){
					if(item.fid==0){
						menu_main[item.weight]={
							id:item.id,
							name:item.name,
							jb: item.jb,
						}
					}else{
						if(!menu_children[item.fid])menu_children[item.fid]={};
						menu_children[item.fid][item.weight]={
							name:item.name,
							url:item.url,
							jb: item.jb,
						}
					}
				}
				
				//格式化子菜单
				for(var i in menu_main){
					menu_main[i].children=menu_children[menu_main[i].id]
				}

				//置入数据
				this.menu=menu_main
			},
		}
	}
</script>
<style lang="scss" scoped>
    .open-page{
		font-size: 14px;
		margin-left: 10px;
	}
     #show{
		cursor: pointer;
			width: 50px;
			height: 50px;
			line-height: 50px;
			text-align: center;
			font-weight:600;
			margin-top: 1px;
			margin-left: -15px;
        }
	.logo {
		text-align: center;
		padding: 15px 0 0 0;
		img {
			width: 60px;
			padding: 10px 0px;
		}
	}
	.psw_edit{
		cursor: pointer;
		width: 50px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		font-weight:600;
	}
	.main_menu {
		height: 100%;
		overflow-x: hidden;
		background-color: #222654;
	}
	/deep/ .el-menu-item{
		background-color: #222654;
		color: #ffffff;
	}
	.right_container{
		padding: 0;
		color: #222654;
		.right_header{
			padding: 0;
			height: 50px;
			line-height: 50px;
			display: flex;
			justify-content: space-between;
			background-color: #eee;

			.left{
				cursor: pointer;
				width: 200px;
				text-align: center;
			}
			.right{
				display: flex;
				padding-right: 15px;
				.logout_icon{
					cursor: pointer;
					width: 50px;
					height: 50px;
					line-height: 50px;
					text-align: center;
					font-weight:600;
				}
			}
		}

		.container{
			background-color:#aaa;
			
		}
	}
	/deep/ .el-submenu__title{
		width: 50px;
		// border-radius: 5px;
		min-width:100%;
		color: #fff ;
		// background-color: #795a25 !important;
	}
	/deep/.is-active  .el-submenu__title{
		// background-color: #222654;
		color:#409EFF ;
	}
	/deep/.el-submenu   :hover{
		// background-color: #222654;
		color:#409EFF !important;
		background-color: #ffffff;
	}
	/deep/.is-active{
		background-color: #ffffff;
		color:#409EFF ;
	}
	.centre{
		margin-top: 20px;
		width: 80%;
		
	}
	/deep/.el-breadcrumb__item{
		cursor:pointer;
	}
	#badge {
		margin-top: -10px;
		margin-left: 10px;
	}
</style>