<template>
	<el-dialog top="1vh" title="货车详情" width="1050px" :visible.sync="is_show_in_page">
		<el-form class="form_ordinary" label-width="160px">

      <div class="big_tit" style="margin-top: 0px">基本数据</div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="所有人" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.truck_owner_name"></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="车辆识别代号" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.truck_vin_code"></el-input>
        </el-form-item>
      </div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="使用性质" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.truck_used"></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="行驶证发证机关" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.dl_ca"></el-input>
        </el-form-item>
      </div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="行驶证注册日期" style="width: 45%">
          <el-date-picker style="width: 265px" v-model="time.dl_reg_date" type="date" placeholder="选择日期" value-format="timestamp"></el-date-picker>
        </el-form-item>
        <el-form-item class="el_form_item" label="行驶证发证日期" style="width: 45%">
           <el-date-picker style="width: 265px" v-model="time.dl_award_date" type="date" placeholder="选择日期" value-format="timestamp"></el-date-picker>
        </el-form-item>
      </div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="道路运输证号" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.tl_code"></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="道路运输经营许可号" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.tl_cert_code"></el-input>
        </el-form-item>
      </div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="车牌号" style="width: 45%">
          <el-input
            class="el_inner_width"
            v-model="form.truck_plate_num"
			
          ></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="车牌类型" style="width: 45%" >
          <el-select class="el_inner_width" v-model="form.truck_plate_type">
            <el-option label="未选择" value="0"></el-option>
            <el-option label="黄牌" value="1"></el-option>
            <el-option label="蓝牌" value="2"></el-option>
            <el-option label="绿牌" value="3"></el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="额定载重(吨)" style="width: 45%" >
          <el-input class="el_inner_width" v-model="form.truck_weight" clearable></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="额载体积(方)" style="width: 45%">
          <el-input class="el_inner_width" v-model="form.truck_volume" clearable></el-input>
        </el-form-item>
      </div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="能源类型" style="width: 45%" >
          <el-select class="el_inner_width" v-model="form.en_type">
            <el-option label="汽油" value="A"></el-option>
            <el-option label="柴油" value="B"></el-option>
            <el-option label="电" value="C"></el-option>
            <el-option label="混合油" value="D"></el-option>
            <el-option label="天然气" value="E"></el-option>
            <el-option label="液化石油气" value="F"></el-option>
            <el-option label="甲醇" value="L"></el-option>
            <el-option label="乙醇" value="M"></el-option>
            <el-option label="太阳能" value="N"></el-option>
            <el-option label="混合动力" value="O"></el-option>
            <el-option label="无" value="Y"></el-option>
            <el-option label="其他" value="Z"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="el_form_item" label="货车类型" style="width: 45%">
          <el-cascader class="el_inner_width" @input="truck_type_change(form)" v-model="form.truck_type" :options="truck_type" placeholder="必填" filterable ></el-cascader>
        </el-form-item>
      </div>
      <div class="tab1_inner">
		<el-form-item class="el_form_item" label="车辆类型" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.truck_type1"></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="总质量" style="width: 45%">
            <el-input class="el_inner_width" v-model="form.truck_weight_max"></el-input>
        </el-form-item>
      </div>
      <div class="tab1_inner">
		<el-form-item class="el_form_item" label="是否默认车辆" style="width: 45%">
			 <el-select class="el_inner_width" v-model="form.is_default">
				<el-option label="是" value="1"></el-option>
				<el-option label="否" value="2"></el-option>
			</el-select>
        </el-form-item>
      </div>

			<div class="big_tit">证件相关</div>
			<div class="tab1_inner">
				<div class="el_form_item" label="" style="width:48%">
					<div class="img_btn">
						<div v-if="!dl_img_src" class="btn">未上传</div>
						<el-image
							v-else
							class="img"
							:src="dl_img_src"
							:previewSrcList="srcList"
							:z-index = 3000
							>
						</el-image>
						<!-- <img  v-else :src="dl_img_src" class="img" @click="show_img(dl_img_src)"> -->
					</div>
					<div class="bottom_text">行驶证
					 <el-button class="btn_left" size="mini" type="text" @click="voucher_add(1)" v-if="truck_info.status_text != '审核通过'">重新上传</el-button>
					</div>
				</div>
				<div class="el_form_item" style="width:48%;margin-left:1%">
					<div class="img_btn">
						<div v-if="!tl_img_src" class="btn">未上传</div>
						 <el-image
								v-else
								class="img"
								:src="tl_img_src"
								:previewSrcList="tlList"
								:z-index = 3000
								>
							</el-image>
						<!-- <img  v-else :src="tl_img_src" class="img" @click="show_img(tl_img_src)"> -->
					</div>
					<div class="bottom_text">道路运输证
						<el-button class="btn_left" size="mini" type="text" @click="voucher_add(2)" v-if="truck_info.status_text != '审核通过'">重新上传</el-button>
					</div>
				</div>
			</div>
			<div class="tab1_inner">
				<div class="el_form_item" label="" style="width:48%" v-if="truck_info.dl_img2">
					<div class="img_btn">
						<!-- <div v-if="!dl_img_src2" class="btn">未上传</div> -->
						<el-image
							
							class="img"
							:src="dl_img_src2"
							:previewSrcList="dl_img_src2_list"
							:z-index = 3000
							>
						</el-image>
						<!-- <img  v-else :src="dl_img_src" class="img" @click="show_img(dl_img_src)"> -->
					</div>
					<div class="bottom_text">行驶证副页
					 <el-button class="btn_left" size="mini" type="text" @click="voucher_add(1)" v-if="truck_info.status_text != '审核通过'">重新上传</el-button>
					</div>
				</div>
				<div class="el_form_item" style="width:48%;margin-left:1%" v-if="truck_info.truck_inspection">
					<div class="img_btn">
						 <el-image
								
								class="img"
								:src="truck_inspection"
								:previewSrcList="truck_inspection_list"
								:z-index = 3000
								>
							</el-image>
						<!-- <img  v-else :src="tl_img_src" class="img" @click="show_img(tl_img_src)"> -->
					</div>
					<div class="bottom_text">年检页
						<el-button class="btn_left" size="mini" type="text" @click="voucher_add(2)" v-if="truck_info.status_text != '审核通过'">重新上传</el-button>
					</div>
				</div>
			</div>

			<!-- <div class="big_tit">车辆来源</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="来源说明" style="width:49%">
					<el-input class="el_inner_width" v-model="form.truck_source" disabled></el-input>
				</el-form-item>
				<div class="el_form_item" label="" style="width:48%">
					<div class="img_btn">
						<div v-if="!truck_source_proof_src" class="btn">未上传</div>
						<img  v-else :src="truck_source_proof_src" class="img" @click="show_img(truck_source_proof_src)">
					</div>
					<div class="bottom_text">车辆来源凭证</div>
				</div>
			</div> -->


			<div class="big_tit">审核相关</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="失败原因" style="width:100%">
					 <el-autocomplete
						class="el_inner_width"
						v-model="form.check_faild_reason"
						:fetch-suggestions="querySearch"
						placeholder="请输入内容"
					></el-autocomplete>
					<!-- <el-input class="el_inner_width" v-model="form.check_faild_reason" clearable></el-input> -->
				</el-form-item>
			</div>

		</el-form>

		<div class="bottom_btns" >
			<el-checkbox v-model="manual_ver_checked" @change="manual_ver_change">五证齐全</el-checkbox>
			<el-button style="margin-left:20px" type="primary" @click="open_examine_view()">审核通过</el-button>
			<el-button type="success" @click="pass()">保存</el-button>
			<el-button type="danger" @click="unpass()">驳回</el-button>
		</div>

		<el-dialog
			top="2vh"
			width="90%"
			title="查看大图"
			:modal="false"
			:visible.sync="img.is_show"
		>
			<img :src="img.src" style="width:100%">
		</el-dialog>
		<!-- 凭证添加界面 -->
		<el-dialog
			title="证件上传"
			top="2vh"
			width="500px"
			:visible.sync="upl.is_show"
			:modal="false"
			>
			<div class="upl">
				<div class="img_area" @click="voucher_add_sub">
				<img v-if="upl.src" :src="upl.src" class="show" />
				<i v-else class="el-icon-plus avatar-uploader-icon icon"></i>
				</div>

				<div class="btn_area">
				<el-button type="primary" @click="voucher_sub(num)">上传</el-button>
				</div>
			</div>
		</el-dialog>
	</el-dialog>
</template>
<script>
	import truck_type_handle from '@/my/_truck_type_handle.js'
	export default {
		props:{
			is_show:Number,//是否显示
			truck_info:Object,//货车详情
		},
		data() {
			return {

				//页面是否显示
				is_show_in_page:false,

				//货车编号
				truck_inner_num:'',
				manual_ver_checked:'',
				//表单
				form:{
					truck_plate_num: "", //车牌号
					truck_plate_type: "", //车牌照类型(0:未知,1:黄牌,2:蓝牌,3:绿牌)
					truck_weight: "", //货车额定载重(单位:吨)
					truck_volume: "", //货车额载体积(单位:方)
					en_type: "", //能源类型(A:汽油,B:柴油,C:电,D:混合油,E:天然气,F:液化石油气,L:甲醇,M:乙醇,N:太阳能,O:混合动力,Y:无,Z:其他)
					truck_type: null, //货车类型
					truck_source: "", //车辆来源
					check_faild_reason: "", //审核失败原因
					truck_type1:'',
					truck_vin_code:'', // 车辆识别代号 
					truck_used:'', // 使用性质 
					dl_ca:'', //行驶证发证机关 
					dl_reg_date:'', //行驶证注册日期 
					dl_award_date:'',// 行驶证发证日期 
					tl_code:'', //道路运输证号
					
					tl_cert_code:'', //道路运输证经营许可证号
					truck_weight_max:'', //车辆总重(单位:吨)
					truck_owner_name:'', //所有人姓名 

					manual_ver:'',//五证是否齐全

					is_default:'',//是否默认车辆
				},
				//日期
				time:{
					dl_reg_date:'',
					dl_award_date:'',
				},
				//行驶证
				dl_img_src:'',
				dl_img_src2:'',//行驶证副业
				dl_img_src2_list:[],
				truck_inspection:'',//年检页
				truck_inspection_list:[],
				srcList:[],
				//道路运输证
				tl_img_src:'',
				tlList:[],
				//车辆来源证明
				truck_source_proof_src:'',
				
				//大图
				img:{
					is_show:false,
					src:''
				},
				//上传界面
				upl: {
					//是否显示上传界面
					is_show: false,

					//地址
					src: "",

					//备注
					mark: "",
				},
				//车型
				truck_type:[],

				//失败原因列表
				restaurants: [],
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
					this.img.is_show=false
				}
			},
			truck_info(new_data){
				if(new_data){
					this.load_data(new_data)
				}
			}
		},
		created() {

			//获取参数
			this.get_creat_para();
			
		},
		mounted() {
		  this.restaurants = this.loadAll();
		},
		methods: {
			manual_ver_change(vuale){
				if(vuale){
					this.form.manual_ver =2
				}else{
					this.form.manual_ver = 1
				}
			},
			//审核通过
			open_examine_view(){
				//询问
				this.$my.other.confirm({
					content:"点击'确定'通过审核",
					confirm:()=>{
						//调接口
						this.$my.net.req({
							data:{
								mod:'truck',
								ctr:'truck_pass_by_admin',
								truck_inner_num:this.truck_info.truck_inner_num,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.is_show_in_page=false
								this.$emit('pass')
							},
						});
					}
				});
			},
			truck_type_change(new_data){
				//车辆功能优化
				if(new_data.truck_type[0] == '4.2米'){
					this.form.truck_plate_type = '2'
					this.form.truck_weight_max = '4.495'
					this.form.truck_weight = '1.8'
					this.form.truck_volume ='1'
					this.form.en_type = 'B'
				}else if(new_data.truck_type[0] == '6.8米' || new_data.truck_type[0] == '7.2米'){
					this.form.truck_plate_type = '1'
					this.form.truck_weight = '8'
					this.form.truck_weight_max = '16'
					this.form.truck_volume ='1'
					this.form.en_type = 'B'
				}else if(new_data.truck_type[0] == '9.6米'){
					this.form.truck_plate_type = '1'
					this.form.truck_weight_max = '25'
					this.form.truck_weight = '14'
					this.form.truck_volume ='1'
					this.form.en_type = 'B'
				}else if(new_data.truck_type[0] == '12.5米' || new_data.truck_type[0] == '13米'){
					this.form.truck_plate_type = '1'
					this.form.truck_weight = '40'
					this.form.truck_weight_max = '48'
					this.form.truck_volume ='1'
					this.form.en_type = 'B'
				}
			},
			querySearch(queryString, cb) {
				var restaurants = this.restaurants;
				var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
				// 调用 callback 返回建议列表的数据
				cb(results);
			},
			createFilter(queryString) {
				return (restaurant) => {
				return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
				};
			},
			loadAll() {
				return [
				{ "value": "请上传车辆道路运输证", "address": "请上传车辆道路运输证" },
				{ "value": "请上传行驶证", "address": "请上传行驶证" },
				];
			},
			show_img(src){
				this.img.src=src,
				this.img.is_show=true;
			},
			//初始化
			load_data(new_data){
				console.log(new_data)
				// 置入数据
				this.truck_inner_num=new_data.truck_inner_num;
				this.truck_id = new_data.id;
				this.form.truck_plate_num = new_data.truck_plate_num;
				this.form.truck_plate_type = new_data.truck_plate_type;
				this.form.truck_weight = new_data.truck_weight;
				this.form.truck_volume = new_data.truck_volume;
				this.form.en_type = new_data.en_type;
				this.form.truck_vin_code =new_data.truck_vin_code, // 车辆识别代号
				this.form.truck_used =new_data.truck_used // 使用性质
				this.form.dl_ca =new_data.dl_ca //行驶证发证机关
				this.form.truck_type1 =new_data.truck_type //车辆详情
				this.form.truck_weight_max =new_data.truck_weight_max //车辆总重(单位:吨)
				this.form.truck_owner_name =new_data.truck_owner_name //所有人姓名
				this.form.manual_ver = new_data.manual_ver // 五证是否齐全
				this.form.is_default=new_data.is_default
				if(new_data.manual_ver ==2){
					this.manual_ver_checked = true
				}else{
					this.manual_ver_checked = false
				}
				this.form.truck_type = [
					new_data.truck_type_name,
					new_data.truck_type_name2,
				];
				//车辆功能优化
				if(new_data.truck_type_name == '4.2米'){
					this.form.truck_plate_type = '2'
					this.form.truck_weight_max = '4.495'
					this.form.truck_weight = '1.8'
					this.form.truck_volume ='1'
					this.form.en_type = 'B'
				}else if(new_data.truck_type_name == '6.8米' || new_data.truck_type_name == '7.2米'){
					this.form.truck_plate_type = '1'
					this.form.truck_weight = '8'
					this.form.truck_weight_max = '16'
					this.form.truck_volume ='1'
					this.form.en_type = 'B'
				}else if(new_data.truck_type_name == '9.6米'){
					this.form.truck_plate_type = '1'
					this.form.truck_weight_max = '25'
					this.form.truck_weight = '14'
					this.form.truck_volume ='1'
					this.form.en_type = 'B'
				}else if(new_data.truck_type_name == '12.5米' || new_data.truck_type_name == '13米'){
					this.form.truck_plate_type = '1'
					this.form.truck_weight = '40'
					this.form.truck_weight_max = '48'
					this.form.truck_volume ='1'
					this.form.en_type = 'B'
				}
				this.form.truck_source = new_data.truck_source;
				this.form.check_faild_reason = new_data.check_faild_reason;
				//日期处理
				if(new_data.dl_reg_date !=0){
					this.time.dl_reg_date =Number(new_data.dl_reg_date) *1000
				}
				if(new_data.dl_award_date !=0){
					this.time.dl_award_date =Number(new_data.dl_award_date) *1000
				}
				this.dl_img_src=this.$my.qiniu.make_src('truckdl',new_data.dl_img)
				this.dl_img_src2 = this.$my.qiniu.make_src('truckdl',new_data.dl_img2)
				this.dl_img_src2_list[0]=this.dl_img_src2
				this.truck_inspection = this.$my.qiniu.make_src('truckdl',new_data.truck_inspection)
				this.truck_inspection_list[0]=this.truck_inspection
				this.srcList[0]=this.dl_img_src
				this.tl_img_src=this.$my.qiniu.make_src('truckdl',new_data.tl_img)
				console.log(this.tl_img_src)
				this.tlList[0]=this.tl_img_src
				this.truck_source_proof_src=this.$my.qiniu.make_src('trucksourceproof',new_data.truck_source_proof)
				//有道路运输证 且没有道路运输证号
				if(this.tl_img_src && !new_data.tl_code){
					//道路运输证识别
					this.$my.net.req({
						data:{
							
							mod:'res',
							ctr:'ocr_normal_img',
							car_img:this.tl_img_src
						},callback:(data)=>{
							this.form.tl_code = data.tl_code
							this.form.tl_cert_code = data.tl_cert_code
							//识别不出来默认四个0
							if(data.jy_code){
								this.form.tl_cert_code = data.jy_code
							}else{
								this.form.tl_cert_code ='0000'
							}
							
						}
					})
				}  else if(!new_data.tl_cert_code){
					this.form.tl_code =new_data.tl_code //道路运输证号
					this.form.tl_cert_code ='0000' //道路运输证经营许可证号
				}else{
					this.form.tl_code =new_data.tl_code //道路运输证号
					this.form.tl_cert_code = new_data.tl_cert_code
				}
			},
			voucher_add(num) {
				this.num = num;
				//打开添加凭证界面

				//打开上传界面
				this.upl.is_show = true;

				//清空上次上传的图片
				this.upl.src = "";

				//清空备注
				this.upl.mark = "";
			},
			voucher_sub(num) {
			//上传
			this.$my.qiniu.upl({
				bucket: "driversourceproof",
				file_path: this.upl.src,
				callback: (data) => {
				var key = data.key.split('__v__1')
				//清空
				var dl_img = "";
				var tl_img = "";
				// 判断
				if (num == 1) {
					dl_img = key[0];
				} else {
					tl_img = key[0];
				}
				// //上传
				this.$my.net.req({
					data: {
					mod: "truck",
					ctr: "truck_img",
					id: this.truck_info.id,
					dl_img,
					tl_img,
					},
					callback: (data) => {
					if(dl_img){
						//调用接口
						this.$my.net.req({
							data:{
								mod:'res',
								ctr:'img_ocr',
								img_type:'driving_license',
								img_url:this.$my.qiniu.make_src('truckdl',dl_img)
							},
							callback:(res)=>{
								this.form.truck_plate_num=res.truck_plate_num
								this.form.truck_vin_code = res.truck_vin_code
								this.form.truck_owner_name = res.truck_owner_name
								this.form.truck_plate_num = res.truck_plate_num
								this.time.dl_award_date = new Date(res.dl_award_date).getTime()
								this.time.dl_reg_date =new Date(res.dl_reg_date).getTime()
								this.form.truck_used = res.truck_used
								this.form.dl_ca = res.truck_seal
								this.form.dl_ocr_rel=res
								this.dl_img_src=this.$my.qiniu.make_src('truckdl',dl_img)
								this.srcList[0]=this.dl_img_src
							}
						});
					}
					if(tl_img){
						//道路运输证识别
						this.$my.net.req({
							data:{
								mod:'res',
								ctr:'ocr_normal_img',
								car_img:this.$my.qiniu.make_src('truckdl',tl_img)
							},callback:(data)=>{
								this.form.tl_code = data.tl_code
								//识别不出来默认四个0
								if(data.jy_code){
									this.form.tl_cert_code = data.jy_code
								}else{
									this.form.tl_cert_code ='0000'
								}
								this.tl_img_src=this.$my.qiniu.make_src('truckdl',tl_img)
								this.tlList[0]=this.tl_img_src
							}
						})
					}

				
					//关闭添加凭证界面
					this.upl.is_show = false;

					// //刷新数据
					// this.init();
					},
				});
				},
			});
		},
		voucher_add_sub() {
			//提交凭证

			//选择图片
			this.$my.qiniu.choose_img({
				success: (res) => {
				//预览
				this.upl.src = res.src;
				},
			});
		},
		// 初始化
		init() {
			this.$my.net.req({
				data: {
				mod: "truck",
				ctr: "truck_list_by_admin",
				truck_plate_num: this.truck_info.truck_plate_num,
				},
				callback: (data) => {
				data.list.forEach((item) => {
					if (item.creat_time == this.truck_info.creat_time) {
					this.load_data(item);
					}
				});
				},
			});
		},
			//识别行驶证
			ocr_dl(){

				//调用接口
				this.$my.net.req({
					data:{
						mod:'res',
						ctr:'img_ocr',
						img_type:'driving_license',
						img_url:this.dl_img_src
					},
					callback:(res)=>{
						this.form.truck_plate_num=res.truck_plate_num
						this.form.dl_ocr_rel=res
					}
				});
			},

			//不通过
			unpass(){

				//失败原因
				if(!this.form.check_faild_reason.trim()){
					this.$my.other.msg({
						type:'warning',
						str:'缺少失败原因'
					});
					return;
				}

				//询问
				this.$my.other.confirm({
					content:"点击'确定'驳回审核",
					confirm:()=>{
						//调接口
						this.$my.net.req({
							data:{
								mod:'truck',
								ctr:'truck_edit_by_admin',
								truck_inner_num:this.truck_inner_num,
								truck_plate_num:this.form.truck_plate_num,
								status:3,
								check_faild_reason:this.form.check_faild_reason.trim()
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.is_show_in_page=false
								this.$emit('close');
							},
						});
					}
				});
			},

			//通过
			pass(){

				//取出数据
				let new_data=this.form;
				let start_time = Number(this.time.dl_reg_date) /1000
				let end_time = Number(this.time.dl_award_date) /1000
				//车牌号
				if(!this.$my.check.is_plate_num(new_data.truck_plate_num)){
					this.$my.other.msg({
						type:'warning',
						str:'缺少车牌号'
					});
					return;
				}

				//车牌类型
				if(['1','2','3'].indexOf(new_data.truck_plate_type)==-1){
					this.$my.other.msg({
						type:'warning',
						str:'缺少车牌类型'
					});
					return;
				}

				//额定载重
				if(!this.$my.check.is_num(new_data.truck_weight)){
					this.$my.other.msg({
						type:'warning',
						str:'缺少额定载重'
					});
					return;
				}
				if(new_data.truck_weight<=0){
					this.$my.other.msg({
						type:'warning',
						str:'缺少额定载重'
					});
					return;
				}

				//货车类型
				if(!this.$my.check.is_arr(new_data.truck_type)){
					this.$my.other.msg({
						type:'warning',
						str:'缺少货车类型'
					});
					return;
				}
				if(new_data.truck_type.length!=2){
					this.$my.other.msg({
						type:'warning',
						str:'缺少货车类型'
					});
					return;
				}
				//询问
				this.$my.other.confirm({
					content:"点击'确定'保存",
					confirm:()=>{
						
						//调接口
						this.$my.net.req({
							data:{
								mod:'truck',
								ctr:'truck_edit_by_admin',
								truck_inner_num:this.truck_inner_num,
								truck_plate_num:new_data.truck_plate_num,
								truck_plate_type:new_data.truck_plate_type,
								truck_weight:new_data.truck_weight,
								truck_volume:new_data.truck_volume,
								en_type:new_data.en_type,
								truck_type:new_data.truck_type1,
								truck_type_name:new_data.truck_type[0],
								truck_type_name2:new_data.truck_type[1],
								dl_ocr_rel:new_data.dl_ocr_rel,
								truck_vin_code:new_data.truck_vin_code,
								truck_used:new_data.truck_used,
								dl_ca:new_data.dl_ca,
								dl_reg_date:start_time,
								dl_award_date:end_time,
								tl_code:new_data.tl_code,
								tl_cert_code:new_data.tl_cert_code,
								dl_ca:new_data.dl_ca,
								truck_weight_max:new_data.truck_weight_max,
								truck_owner_name:new_data.truck_owner_name,
								manual_ver:this.form.manual_ver,
								is_default:this.form.is_default
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.is_show_in_page=false
								this.$emit('close');
							},
						});
					}
				});
			},

			//获取创建参数
			get_creat_para(){

				//尝试从缓存中取出
				let creat_para=this.$my.cache.get('cargo_creat_page_creat_para');

				//如果取出成功
				if(creat_para){
					this.truck_type=creat_para.truck_type
					return
				}

				//如果取出失败,去服务器读取
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'cargo_creat_para'
					},
					callback:(data)=>{
						
						//格式化车型数据
						let truck_type=truck_type_handle.for_el_cascader(data.truck_type);
						
						//缓存之
						this.$my.cache.set({
							cargo_creat_page_creat_para:{
								truck_type:truck_type,
								cargo_type:data.cargo_type
							}
						});

						//置入
						this.truck_type=truck_type
					}
				})
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
		.img_btn{
			border: 1px solid #bbb;
			border-radius: 5px;
			cursor: pointer;
			padding: 5px;
			height:300px;
			.btn{
				text-align: center;
				line-height: 290px;
				font-size: 14px;
			}

			.img {
			position: relative;
			display: block;
			height: 290px;
			/deep/.el-image__inner {
				margin: auto;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				width: auto !important;
			}
		 }
		}
		.bottom_text{
			margin-top: 5px;
			text-align: center;
		}
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}
	
	.bottom_btns{
		text-align: center;
		margin-top: 30px;
	}

	.form_ordinary{
		// height: calc(100% - 91px);
		padding: 10px 0;
		overflow-y:auto;
	}
	.upl {
		.img_area {
			border: 1px solid #ccc;
			cursor: pointer;
			padding: 5px;
			.show {
			margin: auto;
			display: block;
			max-width: 100%;
			max-height: 100%;
			}
			.icon {
			text-align: center;
			display: block;
			font-size: 100px;
			height: 500px;
			line-height: 500px;
			}
		}
		.mark_area {
			border: 1px solid #ccc;
			padding: 5px;
			width: 448px;
			margin-top: 10px;
			height: 100px;
			outline: none;
			font-size: 16px;
		}
		.mark_area::placeholder {
			font-size: 20px;
			line-height: 100px;
			text-align: center;
		}
		.btn_area {
			text-align: center;
			margin-top: 15px;
		}
	}
</style>